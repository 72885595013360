import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Optional, Inject } from '@angular/core';
import { zxcvbnOptions, zxcvbn, zxcvbnAsync } from '@zxcvbn-ts/core';
import { translations } from '@zxcvbn-ts/language-en';
import { IPasswordStrengthMeterService } from 'angular-password-strength-meter';
const ZXCVBN_CONFIG = new InjectionToken('ZXCVBN_CONFIG');
const DEFAULT_CONFIG = {
  translations: translations
};
class PasswordStrengthMeterZXCVBNService extends IPasswordStrengthMeterService {
  constructor(options) {
    super();
    if (options) {
      zxcvbnOptions.setOptions(options);
    } else {
      zxcvbnOptions.setOptions(DEFAULT_CONFIG);
    }
  }
  /**
   *  this will return the password strength score in number
   *  0 - too guessable
   *  1 - very guessable
   *  2 - somewhat guessable
   *  3 - safely unguessable
   *  4 - very unguessable
   *
   *  @param password - Password
   */
  score(password) {
    const result = zxcvbn(password);
    return result.score;
  }
  /**
   * this will return the password strength score with feedback messages
   * return type FeedbackResult
   *
   * @param password - Password
   */
  scoreWithFeedback(password) {
    const result = zxcvbn(password);
    return {
      score: result.score,
      feedback: result.feedback
    };
  }
  async scoreAsync(password) {
    const result = await zxcvbnAsync(password);
    return result.score;
  }
  async scoreWithFeedbackAsync(password) {
    const result = await zxcvbnAsync(password);
    return {
      score: result.score,
      feedback: result.feedback
    };
  }
  static {
    this.ɵfac = function PasswordStrengthMeterZXCVBNService_Factory(t) {
      return new (t || PasswordStrengthMeterZXCVBNService)(i0.ɵɵinject(ZXCVBN_CONFIG, 8));
    };
  }
  static {
    this.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
      token: PasswordStrengthMeterZXCVBNService,
      factory: PasswordStrengthMeterZXCVBNService.ɵfac
    });
  }
}
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(PasswordStrengthMeterZXCVBNService, [{
    type: Injectable
  }], () => [{
    type: undefined,
    decorators: [{
      type: Optional
    }, {
      type: Inject,
      args: [ZXCVBN_CONFIG]
    }]
  }], null);
})();
const DEFAULT_PSM_OPTIONS = {
  serviceClass: PasswordStrengthMeterZXCVBNService
};

/* Public API Surface of password-strength-meter default zxcvbn service
*/

/**
 * Generated bundle index. Do not edit.
 */

export { DEFAULT_PSM_OPTIONS, ZXCVBN_CONFIG };
