import { DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeEsMx from '@angular/common/locales/es-MX';
import localePt from '@angular/common/locales/pt';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { TranslateModule } from '@ngx-translate/core';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { esLocale, ptBrLocale } from 'ngx-bootstrap/locale';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgPipesModule } from 'ngx-pipes';
import { ToastContainerDirective, ToastrModule } from 'ngx-toastr';

import { environment } from '../environments/environment';
import { AppRoutingModule } from './/app-routing.module';
import { AdminModule } from './admin/admin.module';
import { ApiProvider } from './api.provider';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { CompaniesComponent } from './companies/companies.component';
import { CompanyModule } from './company/company.module';
import { DocumentsComponent } from './components/documents/documents.component';
import { EmailVerificationComponent } from './components/email-verification/email-verification.component';
import { HeaderComponent } from './components/header/header.component';
import { MessageMenuComponent } from './components/message-menu/message-menu.component';
import { MessageModalComponent } from './components/message-modal/message-modal.component';
import { MessageToggleComponent } from './components/message-toggle/message-toggle.component';
import { MessagesComponent } from './components/messages/messages.component';
import { NavBarComponent } from './components/navbar/navbar.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { SelectMarketComponent } from './components/select-market/select-market.component';
import { TrackComponent } from './components/track/track.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { DefaultErrorHandler } from './default-error-handler';
import { MessageDropdownDirective } from './directives/message-dropdown.directive';
import localeEs from './es-AR-agree';
import { MarketModule } from './market/market.module';
import { BarterService } from './services/barter.service';
import { CacheService } from './services/cache.service';
import { CompanyService } from './services/company.service';
import { ComponentCommService } from './services/component-comm.service';
import { CurrentDateService } from './services/current-date.service';
import { DataDogLoggerService } from './services/data-dog-logger.service';
import { DefaultLanguageService } from './services/default-language.service';
import { ErrorService } from './services/error.service';
import { IntercomService } from './services/intercom.service';
import { LocationService } from './services/location.service';
import { MailVerifiedGuard } from './services/mail-verified-guard.service';
import { PusherService } from './services/pusher.service';
import { TeamService } from './services/team.service';
import { ThemeModule } from './theme/theme.module';
import { AutoFocusDirective } from './ui/directives/auto-focus.directive';
import { UiModule } from './ui/ui.module';

registerLocaleData(localeEs, 'es');
registerLocaleData(localeEsMx, 'es-MX');
registerLocaleData(localePt, 'pt');
defineLocale('es', esLocale);
defineLocale('pt', ptBrLocale);

@NgModule({
  declarations: [
    AppComponent,
    MessagesComponent,
    PageNotFoundComponent,
    CompaniesComponent,
    HeaderComponent,
    EmailVerificationComponent,
    NavBarComponent,
    MessageMenuComponent,
    MessageToggleComponent,
    MessageDropdownDirective,
    MessageModalComponent,
    SelectMarketComponent,
    DocumentsComponent,
    UserProfileComponent,
    TrackComponent,
    AutoFocusDirective
  ],
  imports: [
    TranslateModule.forRoot(),
    BrowserModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    UiModule,
    CompanyModule,
    AdminModule,
    MarketModule,
    AppRoutingModule,
    NgPipesModule,
    ToastrModule.forRoot({
      timeOut: 8000,
      enableHtml: true,
      progressBar: true,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
      includeTitleDuplicates: true,
      newestOnTop: false
    }),
    ToastContainerDirective,
    InfiniteScrollModule,
    AuthModule,
    NgMapsGoogleModule,
    ThemeModule
  ],
  providers: [
    {
      provide: ErrorHandler,
      useClass: DefaultErrorHandler
    },
    { provide: LOCALE_ID, useValue: "es-AR" },

    ErrorService,
    LocationService,
    IntercomService,
    CompanyService,
    BarterService,
    TeamService,
    MailVerifiedGuard,
    ApiProvider,
    PusherService,
    DatePipe,
    DecimalPipe,
    ComponentCommService,
    CurrentDateService,
    DefaultLanguageService,
    DataDogLoggerService,
    CacheService,
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: environment.google.apiKey,
        libraries: ['places', 'marker']
      }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
